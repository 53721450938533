import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {
    return (
        <section className="contact-area ptb-50 pb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Thank You!</h2>
                    <p>We will follow up as soon as possible!</p>
                </div>

                
            </div>
        </section>
    )
}

export default ContactForm